import {
    LinkBox,
    Popover,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    SkeletonCircle,
    useClipboard
} from '@chakra-ui/react';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "next-share";
import Image from "next/image";
import { usePetition } from "../hooks/usePetition";

function formatHastags(hashtags) {
    if (hashtags === undefined) {
        return ['ipco']
    }

    const hashtagsSplitted = hashtags.split('#')
    let indice = 0

    while (indice >= 0) {
        hashtagsSplitted.splice(indice, 1);
        indice = hashtagsSplitted.indexOf('');
    }
    return hashtagsSplitted
}

export default function PetitionShareButtons() {
    const { petitionData } = usePetition()
    const url = `${process.env.NEXT_PUBLIC_PROJECT_URL}/peticoes/${petitionData.slug}`

    const formattedHashtags = formatHastags(petitionData.hashtags)
    const { hasCopied, onCopy } = useClipboard(url)

    return (
        <>
            {
                !petitionData.social_description ?
                    <>
                        <SkeletonCircle />
                        <SkeletonCircle />
                        <SkeletonCircle />
                        <SkeletonCircle />
                        <SkeletonCircle />
                    </> :
                    <>

                        <FacebookShareButton
                            url={url}
                            quote={petitionData.social_description}
                            windowWidth={800}
                            hashtag={`#${formattedHashtags[0]}`}
                        >
                            <Image
                                src="/Facebook.svg"
                                width={30}
                                height={30}
                                alt="Compartilhe no Facebook"
                            />
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={url}
                            title={petitionData.social_description}
                            windowWidth={800}
                            hashtags={formattedHashtags}
                        >
                            <Image
                                src="/twitter.svg"
                                width={30}
                                height={30}
                                alt="Compartilhe no Twitter"
                            />
                        </TwitterShareButton>

                        <WhatsappShareButton
                            url={`Acabei de assinar essa petição: ${url} :: *Assine* você também`}
                            title={petitionData.social_description}
                            windowWidth={800}
                            separator=":: "
                        >
                            <Image
                                src="/whatsapp.svg"
                                width={30}
                                height={30}
                                alt="Compartilhe no Whatsapp"
                            />
                        </WhatsappShareButton>

                        <TelegramShareButton
                            url={url}
                            title={petitionData.social_description}
                            windowWidth={800}
                        >
                            <Image
                                src="/telegram.svg"
                                width={30}
                                height={30}
                                alt="Compartilhe no Telegram"
                            />
                        </TelegramShareButton>

                        <EmailShareButton
                            url={url}
                            subject={`Petição - ${petitionData.social_description}`}
                            windowWidth={800}
                            body={`Esse é o link da petição: `}
                        >
                            <Image
                                src="/email.svg"
                                width={30}
                                height={30}
                                alt="Compartilhe no Gmail"
                            />
                        </EmailShareButton>

                        <Popover>
                            <PopoverTrigger>
                                <LinkBox
                                    m="0"
                                    p="0"
                                    title={hasCopied ? "Link copiado" : "Copiar o link"}
                                    onClick={onCopy}
                                >
                                    <Image
                                        src={hasCopied ? "/link_clicado.svg" : "/link.svg"}
                                        width={30}
                                        height={30}
                                        alt="Compartilhe"

                                    />
                                </LinkBox>
                            </PopoverTrigger>
                            <PopoverContent color="white" bg="blueBrand.500" borderColor="blueBrand.500">
                                <PopoverCloseButton />
                                <PopoverHeader fontWeight="bold" border="0">Link copiado!</PopoverHeader>
                            </PopoverContent>
                        </Popover>
                    </>
            }
        </>
    )
}