import {Heading} from '@chakra-ui/react';

export function MyHeading(props) {
    return (
        <>
            <Heading
                textTransform="uppercase"
                fontWeight="800"
                {...props}
            >
                {props.children}
            </Heading>
        </>
    )
}