import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    InputGroup,
    Text,
    useToast
} from '@chakra-ui/react';
import {MyHeading} from "../MyChackra/MyHeading";
import {useRouter} from "next/router";
import {useForm} from "react-hook-form";
import {useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Select} from "@chakra-ui/select";

export function PetitionFinishedMessage() {
    const {pathname} = useRouter()
    const color = (pathname === '/' ? 'blue' : 'red')
    const uf = [{"sigla": "AC"}, {"sigla": "AL"}, {"sigla": "AM"}, {"sigla": "AP"}, {"sigla": "BA"}, {"sigla": "CE"}, {"sigla": "DF"}, {"sigla": "ES"}, {"sigla": "GO"}, {"sigla": "MA"}, {"sigla": "MG"}, {"sigla": "MS"}, {"sigla": "MT"}, {"sigla": "PA"}, {"sigla": "PB"}, {"sigla": "PE"}, {"sigla": "PI"}, {"sigla": "PR"}, {"sigla": "RJ"}, {"sigla": "RN"}, {"sigla": "RO"}, {"sigla": "RR"}, {"sigla": "RS"}, {"sigla": "SC"}, {"sigla": "SE"}, {"sigla": "SP"}, {"sigla": "TO"},]

    const reRef = useRef()
    const {handleSubmit, register, formState: {errors, isSubmitting}} = useForm()
    const [isSubscribed, setIsSubscribed] = useState(false)
    const toast = useToast()

    async function onSubmit(values) {
        const token = await reRef.current.executeAsync()
        reRef.current.reset()

        const endpoint = "/api/newsletter"
        const res = await fetch(endpoint,
            {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    "email": values.email,
                    "first_name": values.firstName,
                    "last_name": values.lastName,
                    "city": values.city,
                    "state": values.state,
                    token
                })
            })
        if (res.ok || res.status === 409) {
            setIsSubscribed(true)
            return
        }

        toast({
            status: "error",
            title: "Erro interno",
            description: "Não conseguimos salvar seu cadastro",
            variant: "left-accent",
            duration: 9000,
            position: "top-right",
            isClosable: true
        })

    }

    return (
        <Box textAlign="center" mb="1rem" mx={2}>
            <MyHeading>A luta começa com <Text display={"inline"} color={`${color}.base`}>você</Text></MyHeading>
            <Text fontSize="18" mt="0.5rem">
                Tenha certeza de nunca perder uma campanha importante!
            </Text>
            {
                isSubscribed ?
                    (
                        <Alert status={"success"} my={8} variant={"left-accent"}>
                            <AlertIcon/>
                            <AlertDescription>
                                <AlertTitle>Cadastrado com sucesso!</AlertTitle>
                                Agora toda vez que lançarmos uma petição você vai receber um e-mail avisando
                            </AlertDescription>
                        </Alert>
                    ) :
                    (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputGroup>
                                <FormControl isInvalid={errors.firstName} autoComplete={"off"}>
                                    <Input
                                        name={"firstName"}
                                        variant="filled"
                                        colorScheme="gray"
                                        placeholder="Digite seu nome"
                                        borderRadius="50"
                                        borderColor="black"
                                        mt="1rem"
                                        w="full"
                                        {...register("firstName", {required: true, minLength: 2, maxLength: 30})}
                                    />
                                    <FormErrorMessage>
                                        {errors.firstName?.type === "required" && "Esse campo é obrigatório"}
                                        {errors.firstName?.type === "minLength" && "Digite seu primeiro nome"}
                                        {errors.firstName?.type === "maxLength" && "Digite apenas o seu primeiro nome"}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.lastName} autoComplete={"off"} ml="1rem">
                                    <Input
                                        name={"lastName"}
                                        variant="filled"
                                        colorScheme="gray"
                                        placeholder="Digite seu sobrenome"
                                        borderRadius="50"
                                        borderColor="black"
                                        mt="1rem"
                                        w="full"
                                        {...register("lastName", {required: true, minLength: 2, maxLength: 30})}
                                    />
                                    <FormErrorMessage>
                                        {errors.lastName?.type === "required" && "Esse campo é obrigatório"}
                                        {errors.lastName?.type === "minLength" && "Digite seu primeiro nome"}
                                        {errors.lastName?.type === "maxLength" && "Digite apenas o seu primeiro nome"}
                                    </FormErrorMessage>
                                </FormControl>
                            </InputGroup>
                            <FormControl isInvalid={errors.email} autoComplete={"off"}>
                                <Input
                                    name={"email"}
                                    variant="filled"
                                    colorScheme="gray"
                                    placeholder="Informe seu e-mail"
                                    borderRadius="50"
                                    borderColor="black"
                                    mt="1rem"
                                    w="full"
                                    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                                />
                                <FormErrorMessage>
                                    {errors.email?.type === "required" && "Esse campo é obrigatório"}
                                    {errors.email?.type === "pattern" && "Digite um e-mail válido"}
                                </FormErrorMessage>
                            </FormControl>
                            <InputGroup mt="1rem">
                                <FormControl isInvalid={errors.city} mr="0.5rem">
                                    <Input
                                        name="city"
                                        variant="filled"
                                        colorScheme="gray"
                                        placeholder="Cidade"
                                        borderRadius="50"
                                        borderColor="black"
                                        {...register("city", {required: true, minLength: 2, maxLength: 30})}
                                    />
                                    <FormErrorMessage pl="1rem">
                                        {errors.city?.type === "required" && "Esse campo é obrigatório"}
                                        {errors.city?.type === "minLength" && "Digite o nome da sua cidade"}
                                        {errors.city?.type === "maxLength" && "Digite apenas o nome da sua cidade"}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.state} w="40%">
                                    <Select
                                        fontFamily='Courier'
                                        name="state"
                                        variant="filled"
                                        colorScheme="gray"
                                        placeholder="-UF-"
                                        borderRadius="50"
                                        borderColor="black"
                                        {...register("state", {required: true})}
                                    >
                                        {
                                            (uf.map((item, index) => {
                                                return <option key={index} value={item.sigla}>{item.sigla}</option>
                                            }))
                                        }
                                    </Select>
                                    <FormErrorMessage pl="1rem">
                                        {errors.state?.type === "required" && "Escolha seu estado"}
                                    </FormErrorMessage>
                                </FormControl>
                            </InputGroup>
                            <Button
                                type="submit"
                                colorScheme={`${color}`}
                                bgColor={`${color}.base`}
                                borderRadius="50"
                                mt="1rem"
                                w="140px"
                                h={"45px"}
                                fontSize={"18px"}
                                fontFamily={"button"}
                                textTransform={"uppercase"}
                                isLoading={isSubmitting}
                            >
                                Confirmar
                            </Button>

                            <ReCAPTCHA
                                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
                                size="invisible"
                                ref={reRef}
                            />
                        </form>
                    )
            }
        </Box>
    )
}