import {Box, Container, Grid, Link} from '@chakra-ui/react';
import Image from "next/image"

export function Footer() {
    const now = new Date()
    const year = now.getFullYear()

    return (
        <footer>
            <Container
                maxW="full"
                h="100px"
                py="1rem"
                backgroundColor="#031520"
                color="white"
                mt="10rem"
                fontFamily="Roboto"
                fontSize="17px"
                fontWeight="light"
                textAlign="center"
            >
                <Box margin="0 auto" w="100px">
                    <Grid templateColumns="repeat(4, 1fr)" gap={1}>
                        <Link href="https://www.instagram.com/institutopco/" isExternal>
                            <Image
                                src="/instagram-logo.svg"
                                width={28}
                                height={28}
                                alt="Logo do Instagram"
                            />
                        </Link>
                        <Link href="https://www.facebook.com/Inst.PCO" isExternal>
                            <Image
                                src="/facebook-logo.svg"
                                width={28}
                                height={28}
                                alt="Logo do Facebook"
                            />
                        </Link>
                        <Link href="https://twitter.com/InstitutoPCO" isExternal>
                            <Image
                                src="/twitter-logo.svg"
                                width={28}
                                height={28}
                                alt="Logo do Twitter"
                            />
                        </Link>
                    </Grid>
                </Box>
                © {year} Instituto Plinio Corrêa de Oliveira
            </Container>
        </footer>
    )
}