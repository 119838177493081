import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { MyHeading } from "../MyChackra/MyHeading";
import PetitionShareButtons from "../PetitionShareButtons";

export function ThanksForSignin() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pb={12}>
          <center>
            <MyHeading fontSize='35' mb='0.5rem'>
              obrigado !
            </MyHeading>
            <Text fontSize='20'>
              São pessoas como você que estão fazendo a diferença
            </Text>
          </center>
          <Divider my='1rem' />
          <Box p='0' pt={2} w='full' textAlign='center'>
            <Text fontSize='27'>Turbine essa petição</Text>
            <LinkBox>
              <LinkOverlay
                href={`https://doacao.ipco.org.br/paginas/peticao`}
                // href={`https://doacao.ipco.org.br/paginas/peticao?email=${email}&first_name=${firstName}&petition=${petitionTitle}`}
                isExternal={true}
              >
                <Button
                  variant='solid'
                  colorScheme='red'
                  bgColor='redBrand.500'
                  fontFamily='Roboto'
                  textTransform='uppercase'
                  maxW='100%'
                  whiteSpace='normal'
                  py='1.5rem'
                >
                  Faça uma doação agora mesmo
                </Button>
              </LinkOverlay>
            </LinkBox>
            <Text fontSize='17' fontFamily='Roboto'>
              Sua doação garante a nossa independência
            </Text>
            <Text fontSize='22' mb='0.5rem' mt={16}>
              Compartilhe essa petição
            </Text>
            <Box w='280px' margin='0 auto' textAlign='center'>
              <Grid gap={1} templateColumns={"repeat(6, 1fr)"}>
                <PetitionShareButtons />
              </Grid>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
