import {createContext, useContext, useEffect, useState} from 'react'

const PetitionContext = createContext({})

export function PetitionProvider({children}) {
    const [petitionData, setPetitionData] = useState({})
    const [subscribers, setSubscribers] = useState(0)

    useEffect(() => {
        setSubscribers(petitionData.subscribers)
    }, [petitionData])

    function addSubscription() {
        setSubscribers(subscribers + 1)
    }

    return (
        <PetitionContext.Provider value={{
            setPetitionData,
            petitionData,
            subscribers,
            addSubscription
        }}>
            {children}
        </PetitionContext.Provider>
    )
}

export function usePetition() {
    return useContext(PetitionContext)
}