import {createContext, useContext, useState} from "react";

const SignatureContext = createContext({})

export function SignatureProvider({children}) {
    const [isSigned, setIsSigned] = useState(false)

    return (<SignatureContext.Provider value={{
        isSigned, setIsSigned
    }}>
        {children}
    </SignatureContext.Provider>)
}

export function useSignature() {
    return useContext(SignatureContext)
}